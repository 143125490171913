import StarfieldAnimation from "react-starfield-animation";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div
      style={{
        background: 'url("./bg.jpg")',
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          color: "rgb(255, 255, 255, 0.1)",
          fontSize: "3em",
          fontFamily: "Helvetica Neue, sans-serif",
          textShadow: "rgba(0, 0, 0, 0.5) 2px 2px 8px;",
          userSelect: "none",
        }}
      >
        nova.lt
      </h1>
      <StarfieldAnimation
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
}

export default App;
